import Color from "colorjs.io";

export const useColors = () => {
  const colorMode = useColorMode();

  function setLuminance(color, options) {
    if (!color) {
      return "#000000";
    }

    const clr = new Color(color);

    clr.set({
      "lch.l": options?.luminance || (colorMode.value == "light" ? 44 : 27),
    });

    return clr.toString({
      precision: options?.precision || 5,
      format: options?.format || "hex",
    });
  }

  function setLuminanceBathConceptProject(color, options) {
    if (!color) {
      return colorMode.value == "light" ? "#ffffff" : "#000000";
    }

    const clr = new Color(color);
    const LCHclr = clr.to("lch");
    const LIGHT_MODE_LCH_C = LCHclr.lch.c > 15 ? 15 : LCHclr.lch.c;
    const DARK_MODE_LCH_C = LCHclr.lch.c > 7.5 ? 7.5 : LCHclr.lch.c;

    if (colorMode.value == "light") {
      clr.set({
        "lch.l": options?.luminance || 85,
        "lch.c": options?.chroma || LIGHT_MODE_LCH_C,
      });
    } else {
      clr.set({
        "lch.l": options?.luminance || 20,
        "lch.c": options?.chroma || DARK_MODE_LCH_C,
      });
    }

    return clr.toString({
      precision: options?.precision || 5,
      format: options?.format || "hex",
    });
  }

  return {
    setLuminance,
    setLuminanceBathConceptProject,
  };
};
